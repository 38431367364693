import React, {useState, useEffect} from 'react';
import './skills.scss'
import {Tooltip as ReactTooltip} from 'react-tooltip';
import {motion} from "framer-motion";
import {AppWrap, MotionWrap} from "../../wrapper";
import {client, urlFor} from "../../client";

function Skills(props) {

    const [tooltip, showTooltip] = useState(true);

    const [skills, setSkills] = useState([]);
    const [experiences, setExperiences] = useState([]);

    useEffect(() => {
        const query = '*[_type == "experiences" ]'
        const skillQuery = '*[_type == "skills" ]'
        client.fetch(query)
            .then(data => setExperiences(data))
        client.fetch(skillQuery)
            .then(data => setSkills(data))

    }, []);

    return (
        <>
            <h2 className="head-text">Skills & Experiences</h2>

            <div className="app__skills-container">
                <motion.div className="app__skills-list">
                    {skills.filter(skill => skill.icon).sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0)).map((skill, index) => (
                        <motion.div
                            whileInView={{opacity: [0, 1]}}
                            transition={{duration: 0.5}}
                            className="app__skills-item app__flex"
                            key={`${skill.name} - ${index}`}
                        >
                            <div
                                className="app__flex"
                                style={{backgroundColor: skill.bgColor}}
                            >
                                <img src={urlFor(skill.icon)} alt={skill.name}/>
                            </div>
                            <p className="p-text">{skill.name}</p>
                        </motion.div>
                    ))}
                </motion.div>
                <div className="app__skills-exp">
                    {experiences.sort((a, b) => (a.year < b.year) ? 1 : ((b.year < a.year) ? -1 : 0)).map((experience, index) => (
                        <motion.div
                            className="app__skills-exp-item"
                            key={`${experience.year} - ${index}`}
                        >
                            <div className="app__skills-exp-year">
                                <p className="bold-text">{experience.year}</p>
                                {experience.connected && (
                                    <div className="app__skills-exp-line-container">
                                        <div className="app__skills-exp-line-dot"></div>
                                        <div className="app__skills-exp-line"></div>
                                        <div className="app__skills-exp-line-dot"></div>
                                    </div>
                                )}
                            </div>

                            <motion.div className="app__skills-exp-works">
                                {experience.works.map((work) => (
                                    <>
                                        <motion.div
                                            whileInView={{opacity: [0, 1]}}
                                            transition={{duration: 0.5}}
                                            className="app__skills-exp-work"
                                            data-tip={true}
                                            data-for={work.name}
                                            id={`${work.name}-${work.company}`}
                                            key={work.name}
                                            onMouseEnter={() => showTooltip(true)}
                                            onMouseLeave={() => {
                                                showTooltip(false);
                                                setTimeout(() => showTooltip(true), 50);
                                            }}
                                        >
                                            <h4 className="bold-text">{work.name}</h4>
                                            <p className="p-text">{work.company}</p>
                                        </motion.div>
                                        <ReactTooltip
                                            anchorId={`${work.name}-${work.company}`}
                                            effect="solid"
                                            arrowColor="#fff"
                                            className="skills-tooltip"
                                            content={work.desc}
                                        />
                                    </>
                                ))}
                            </motion.div>
                        </motion.div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default AppWrap(
    MotionWrap(Skills, 'app__skills'),
    'skills',
    'app__whitebg'
);