import React, {useEffect, useState} from 'react';
import './footer.scss'
import {Images} from "../../constants";
import {AppWrap, MotionWrap} from "../../wrapper";
import {client} from "../../client";
import SocialMedia from "../../components/SocialMedia/SocialMedia";


function Footer(props) {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);

    const {name, email, message} = formData;

    const [profile, setProfile] = useState(null);
    useEffect(() => {
        const query = '*[_type == "profile" ]'
        client.fetch(query)
            .then(data => setProfile(data.length > 0
                ? data[0]
                : null
            ))
    }, []);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value})
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        const contact = {
            _type: 'contact',
            name,
            email,
            message
        }

        client.create(contact)
            .then(() => {
                setLoading(false);
                setIsFormSubmitted(true);
            })
    }
    return (
        <>
            <h2 className="head-text">
                Take A Coffee & Chat With Me
            </h2>
            <div className='w-25'>
                <SocialMedia row/>
            </div>
            <div className="app__footer-cards">
                <div className="app__footer-card">
                    <img src={Images.email} alt="email"/>
                    <a href="mailto:saadjamilakhtar@gmail.com">{profile ? profile.Email : 'saadjamilakhtar@gmail.com'}</a>
                </div>
                {profile
                    ? profile.Phone
                        ? (<div className="app__footer-card">
                            <img src={Images.mobile} alt="email"/>
                            <a href={`tel:${profile.Phone}`}>{profile.Phone}</a>
                        </div>)
                        : ""
                    : (<div className="app__footer-card">
                        <img src={Images.mobile} alt="email"/>
                        <a href="tel:+1 (123) 456 789">+1 (123) 456 789</a>
                    </div>)
                }
            </div>
            {!isFormSubmitted
                ? (<div className="app__footer-form app__flex">
                    <div className="app__flex">
                        <input type="text" className="p-text" placeholder="Your Name" value={name}
                               onChange={handleChange}
                               name='name'/>
                    </div>
                    <div className="app__flex">
                        <input type="email" className="p-text" placeholder="Your Email" value={email}
                               onChange={handleChange} name='email'/>
                    </div>
                    <div>
                    <textarea
                        name="message"
                        id="message"
                        cols="30"
                        rows="10"
                        className="p-text"
                        placeholder="Message . . ."
                        value={message}
                        onChange={handleChange}
                    />
                    </div>
                    <button className='p-text'
                            onClick={handleSubmit}>{loading ? 'Sending...' : 'Send Message'}</button>
                </div>)
                : (<div>
                    <h3 className="head-text">Thank You For Getting In Touch!</h3>
                </div>)}
        </>
    );
}

export default AppWrap(
    MotionWrap(Footer, 'app__footer'),
    'contact',
    'app__whitebg'
);