import React, {useEffect, useState} from 'react';
import {SocialMedia, NavDots} from "../components";
import {client} from "../client";

const AppWrap = (Component, idName, classNames) => function HOC() {

    const [name, setName] = useState([]);
    useEffect(() => {
        const query = '*[_type == "profile" ]'
        client.fetch(query)
            .then(data => setName(data.length > 0
                ? `${data[0].firstname} ${data[0].lastname}`
                : "Saad Jamil"
            ))
    }, []);
    return (
        <div id={idName} className={`app__container ${classNames}`}>
            <SocialMedia/>
            <div className="app__wrapper app__flex">
                <Component/>

                <div className="copyright">
                    <p className="p-text-small">@{new Date().getFullYear()} {name}</p>
                    <p className="p-text-small">All rights reserved</p>
                </div>
            </div>
            <NavDots active={idName}/>
        </div>
    );
}

export default AppWrap;