import React, {useEffect, useState} from 'react';
import {BsLinkedin, BsGithub, BsFacebook, BsYoutube, BsInstagram, BsStackOverflow, BsMedium} from "react-icons/bs";
import {client} from "../../client";
import {SiUpwork} from "react-icons/si";

function SocialMedia(props) {
    const icons = {
        'LinkedIn': (<BsLinkedin/>),
        'Youtube': (<BsYoutube/>),
        'Facebook': (<BsFacebook/>),
        'Github': (<BsGithub/>),
        'Instagram': (<BsInstagram/>),
        'Stackoverflow': (<BsStackOverflow/>),
        'Upwork': (<SiUpwork/>),
        'Medium': (<BsMedium/>),
    }
    const [socials, setSocials] = useState([]);
    useEffect(() => {
        const query = '*[_type == "socials" ]'
        client.fetch(query)
            .then(data => setSocials(data))
    }, []);
    return (
        <div className={`app__social ${props.row && 'app_social_row'}`}>
            {socials.map((social) => (
                <div key={social.title}>
                    <a href={social.link}>{icons[social.title]}</a>
                </div>
            ))}

        </div>
    );
}

export default SocialMedia;