import React, {useEffect, useState} from 'react';
import './navbar.scss'
import {Images} from "../../constants";
import {HiMenuAlt4, HiX} from "react-icons/hi";
import {motion} from "framer-motion";
import {client, urlFor} from "../../client";

export const navItems = [
    'home', 'about', 'work', 'skills', 'testimonials' ,'contact'
]

function Navbar(props) {

    const [profile, setProfile] = useState(null);
    useEffect(() => {
        const query = '*[_type == "profile" ]'
        client.fetch(query)
            .then(data => setProfile(data.length > 0
                ? data[0]
                : null
            ))
    }, []);

    const [toggle, setToggle] = useState(false);
    return (
        <nav className='app__navbar'>
            <div className='app__navbar-logo'>
                <img src={profile ? urlFor(profile.logo) :Images.logo} alt="LOGO"/>
            </div>
            <ul className='app__navbar-links'>
                {navItems.map((item) => (
                        <li key={`link-${item}`} className='app__flex p-text'>
                            <div/>
                            <a href={`#${item}`}>{item}</a>
                        </li>
                    )
                )}
            </ul>
            <div className="app__navbar-menu">
                <HiMenuAlt4 onClick={() => setToggle(true)}/>
                {
                    toggle && (
                        <motion.div
                            whileInView={{x: [300, 0]}}
                            transition={{duration: 0.85, ease: 'easeOut'}}
                        >
                            <HiX onClick={() => setToggle(false)}/>
                            <ul>
                                {navItems.map((item) => (
                                        <li key={item}>
                                            <a href={`#${item}`} onClick={() => setToggle(false)}>{item}</a>
                                        </li>
                                    )
                                )}
                            </ul>
                        </motion.div>
                    )
                }
            </div>
        </nav>
    );
}

export default Navbar;