import React from 'react';
import {navItems} from "../Navbar/Navbar";

function NavDots({active}) {
    return (
        <div className="app__navigation">
            {navItems.map((item, ind) => (
                    <a
                        href={`#${item}`}
                        key={item + ind}
                        className='app__navigation-dot'
                        style={active === item ? {background: "#313BAC"} : {}}
                    />
                )
            )}
        </div>
    );
}

export default NavDots;