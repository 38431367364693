import React, {useEffect, useState} from 'react';
import './header.scss'
import {motion} from "framer-motion";
import {Images} from "../../constants";
import {AppWrap} from "../../wrapper";
import {client, urlFor} from "../../client";

const scaleVariance = {
    whileInView: {
        scale: [0, 1],
        opacity: [0, 1],
        transition: {
            duration: 1,
            ease: 'easeInOut'
        }
    }
}

function Header(props) {

    const [profile, setProfile] = useState(null);
    useEffect(() => {
        const query = '*[_type == "profile" ]'
        client.fetch(query)
            .then(data => setProfile(data.length > 0
                ? data[0]
                : null
            ))
    }, []);

    return (
        <div className="app__header app__flex">
            <motion.div
                whileInView={{x: [-100, 0], opacity: [0, 1]}}
                transition={{duration: 0.5}}
                className='app__header-info'
            >
                <div className="app__header-badge">
                    <div className="badge-cmp app__flex">
                        <span>👋</span>
                        <div style={{marginLeft: 20}}>
                            <p className='p-text'>Hello, I am</p>
                            <h1 className="head-text">{profile && profile.firstname ? profile.firstname : "Saad"}</h1>
                        </div>
                    </div>

                    {profile && profile.Designations.length > 0
                        ? (<div className="tag-cmp app__flex">
                                {profile.Designations.map((designation) => (
                                    <p className="p-text text-bold" key={designation}>{designation}</p>))}
                            </div>
                        )
                        : (
                            <div className="tag-cmp app__flex">
                                <p className="p-text text-bold">Full Stack Web Developer</p>
                                <p className="p-text text-bold">Data Engineer</p>
                            </div>)
                    }

                </div>
            </motion.div>

            <motion.div
                whileInView={{opacity: [0, 1]}}
                transition={{duration: 0.5, delayChildren: 0.5}}
                className='app__header-img'
            >

                <img src={profile && profile.profileImg ? urlFor(profile.profileImg) : Images.profileImg} alt="Profile"/>
                <motion.img
                    whileInView={{scale: [0, 1]}}
                    transition={{duration: 1, ease: 'easeInOut'}}
                    className='overlay_circle'
                    src={Images.circle}
                    alt={'circle'}
                >

                </motion.img>
            </motion.div>
            <motion.div
                variants={scaleVariance}
                whileInView={scaleVariance.whileInView}
                className='app__header-circles'
            >

                {profile && profile.Technologies.length > 0
                    ? profile.Technologies.slice(0, 5).map((circle, ind) => (
                        <div className="circle-cmp app__flex" key={`circle-${ind}`}>
                            <img src={urlFor(circle)} alt="circle"/>
                        </div>
                    ))
                    : [Images.react, Images.django, Images.python].map((circle, ind) => (
                        <div className="circle-cmp app__flex" key={`circle-${ind}`}>
                            <img src={circle} alt="circle"/>
                        </div>
                    ))
                }

            </motion.div>
        </div>
    );
}

export default AppWrap(Header, 'home');
// export default Header;