import React, {useState, useEffect} from 'react';
import './work.scss'
import {AiFillEye, AiFillGithub} from "react-icons/ai";
import {motion} from "framer-motion";
import {AppWrap, MotionWrap} from "../../wrapper";
import {client, urlFor} from "../../client";

function Work(props) {

    const [active, setActive] = useState('All');
    const [animateCard, setAnimateCard] = useState({y: 0, opacity: 1});
    const [work, setWork] = useState([]);
    const [filterWork, setFilterWork] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const workQuery = '*[_type == "works" ]{ ..., tags[]->{title} }'
        client.fetch(workQuery)
            .then((data) => {
                const sorted = data.sort((a, b) => a.order - b.order)
                setWork(sorted);
                setFilterWork(sorted);
            })
        const categoryQuery = '*[_type == "tags" ]'
        client.fetch(categoryQuery)
            .then((data) => {
                data.forEach((element, index) => data[index] = element.title)
                data.sort()
                data.reverse()
                setCategories(data)
            })

    }, [])

    const handleWorkFilter = (item) => {
        setActive(item);
        setAnimateCard([{y: 100, opacity: 0}])

        setTimeout(() => {
            if (item === 'All') {
                setFilterWork(work)
            } else {
                setFilterWork(work.filter((workItem) => {
                    const tags = []
                    workItem.tags.forEach((element, index) => {
                        tags.push(element.title)
                    })
                    return tags.includes(item)
                }))
            }
            setAnimateCard([{y: 0, opacity: 1}])
        }, 500)
    }

    return (
        <>
            <h2 className="head-text">
                My Creative <span>Portfolio</span> Section
            </h2>
            <div className="app__work-filter">
                {categories.map((item, ind) => (
                    <div
                        key={ind}
                        onClick={() => handleWorkFilter(item)}
                        className={`app__work-filter-item app__flex p-text ${active === item ? 'item-active' : ''}`}
                    >
                        {item}
                    </div>
                ))}
            </div>

            <motion.div
                animate={animateCard}
                transition={{duration: 0.5, delayChildren: 0.5}}
                className='app__work-portfolio'
            >
                {filterWork.map((work, ind) => {
                    if (!String(work._id).startsWith('drafts'))
                        return (
                            <div className="app__work-item app__flex" key={ind}>
                                <div className="app__work-img app__flex">
                                    <img src={work.imgUrl && urlFor(work.imgUrl)} alt={work.title}/>
                                    <motion.div
                                        whileHover={{opacity: [0, 1]}}
                                        transition={{duration: 0.25, ease: 'easeInOut', staggerChildren: 0.5}}
                                        className='app__work-hover app__flex'
                                    >
                                        {work.projectLink
                                        && <a href={work.projectLink} target='_blank' rel="noreferrer">
                                            <motion.div
                                                whileInView={{scale: [0, 1]}}
                                                whileHover={{scale: [1, 0.9]}}
                                                transition={{duration: 0.25}}
                                                className='app__flex'
                                            >
                                                <AiFillEye/>
                                            </motion.div>
                                        </a>
                                        }
                                        {work.codeLink
                                        && <a href={work.codeLink} target='_blank' rel="noreferrer">
                                            <motion.div
                                                whileInView={{scale: [0, 1]}}
                                                whileHover={{scale: [1, 0.9]}}
                                                transition={{duration: 0.25}}
                                                className='app__flex'
                                            >
                                                <AiFillGithub/>
                                            </motion.div>
                                        </a>
                                        }
                                    </motion.div>
                                </div>

                                <div className="app__work-content app__flex">
                                    <h4 className="bold-text">{work.title}</h4>
                                    <p className="p-text" style={{marginTop: 10}}>{work.description}</p>
                                    <div className={`app__work-tag app__flex ${work.border && "bordered"}`}>
                                        <p className="p-text">{work.tags && work.tags[0].title}</p>
                                    </div>
                                </div>
                            </div>
                        )
                })}
            </motion.div>
        </>
    );
}

export default AppWrap(
    MotionWrap(Work, 'app__works'),
    'work',
    'app__primarybg'
);